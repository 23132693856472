import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Title, Text, Span } from "../../components/Core";
import imgPhoto from "../../assets/image/jpg/portfolio-about.jpg";

import GlobalContext from "../../context/GlobalContext";
import { Box, ButtonIcon } from "../../components/Core";
import Availability from "../../components/Availability";

import {
  Button,
  LinkContact,
} from "../../components/Core";

const LinkSocial = styled.a`
  color: #161c2d !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 1.63px;
  transition: 0.4s;
  &:hover {
    color: #161c2d !important;
    transform: translateY(-5px);
  }
  &:visited {
    color: #161c2d !important;
  }
`;

const About = ({ hero = true, bg = "dark", ...rest }) => {
  return (
    <>
      <Section hero={hero} background="#fff" {...rest}>
        <Container>
          <Row className="align-items-center">
            <Col lg="6">
              <div>
                <img src={imgPhoto} className="img-fluid" />
              </div>
            </Col>
            <Col lg="6">
              <div className="pl-lg-4 pt-5 pt-lg-0">
                <Title color="dark" variant="secSm">
                  Hi, that's me. :)
                </Title>
                <Text
                  color="dark"
                  className="mt-3 mt-lg-5"
                  css={`
                    line-height: 1.5;
                  `}
                >
                  I'm an UI/UX Designer, Illustrator and Animator based in Graz, AT whose creative eye and skill has touched a wide array of projects from local business branding to worldwide consumer products.
                  The most important thing on my mind; working on your ideas to draw a smile on your face, by creating a Design that tells a story.
                </Text>
                <Text color="dark" className="mt-4">
                  Shaped by 6+ years of experience with graphic design and communication.
                </Text>
                <div className="mt-4">
                  <Text color="dark">Now, tell me about you.</Text>

                  <Text variant="p">
                    <a
                      href="mailto:lisa.hinterholzer@icloud.com"
                    >
                      <Span color="dark">lisa.hinterholzer@icloud.com</Span>
                    </a>
                  </Text>
                </div>
                
               
                <Box
                  className="text-center d-flex justify-content-left"
                  mt={["3rem", null, "4rem"]}
                >
                  <div className="d-flex flex-column flex-lg-row justify-content-center">
                    <LinkContact
                      href="https://www.instagr.am/lisamarie.hiho"
                      target="_blank"
                      className="mb-2 mb-lg-0"
                    >
                      <i className="icon big icon-logo-instagram"></i> 
                      savory.design
                    </LinkContact>
                    <span className="divider mx-2 mx-lg-4 d-none d-lg-block">
                      |
                    </span>
                    <LinkContact
                      href="https://www.linkedin.com/in/lisa-marie-hinterholzer-337b69200/"
                      target="_blank"
                    >
                      <i className="icon big icon-logo-linkedin"></i> 
                      Lisa Hinterholzer
                    </LinkContact>
                  </div>
                </Box>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default About;
